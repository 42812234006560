import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectCoverflow, Pagination, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import React, { useEffect, useState } from "react";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import Footer from "../../components/Footer_services";
import technology_software from "../../assets/svg/technology_software.svg";
import heart from "../../assets/svg/heart.svg";
import problem from "../../assets/svg/problem.svg";
import bulb from "../../assets/svg/bulb.svg";
import pencil from "../../assets/svg/pencil.svg";
import notebook from "../../assets/svg/notebook.svg";
import astrik from "../../assets/svg/astrik.svg";
import img1 from "../../assets/img/software/img1.png";
import ai from "../../assets/img/software-licencing/ai.png";
import ps from "../../assets/img/software-licencing/ps.png";
import cc from "../../assets/img/software-licencing/cc.png";
import cd from "../../assets/img/software-licencing/cd.png";
import tally from "../../assets/img/software-licencing/tally.png";
import gw from "../../assets/img/software-licencing/gw.png";
import m365 from "../../assets/img/software-licencing/m365.png";
import office from "../../assets/img/software-licencing/office.png";
import ws from "../../assets/img/software-licencing/ws.png";
import win from "../../assets/img/software-licencing/win.png";
import qh from "../../assets/img/software-licencing/qh.png";
import rp from "../../assets/img/software-licencing/razorpay.png";
import "../../css/home.css";
import "../../css/services.css";
import Loader_page from "../../components/Loader_page";

const Software_licencing = () => {
  // Set loading state to true initially
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      // await new Promise((r) => setTimeout(r, 2000));

      setTimeout(() => {
        setLoading(false);
      }, 10);

      // Toggle loading state
      // setLoading((loading) => !loading);
    };

    loadData();
  }, []);

  // If page is in loading state, display
  // loading message. Modify it as per your
  // requirement.
  if (loading) {
    return <Loader_page />;
  }

  // If page is not in loading state, display page.
  else {
    return (
      <>
        {/* <Helmet>
            <title>TMT Steel Bars Manufacturer in Ahmedabad Gujarat India | Rudra TMX</title>
          </Helmet> */}

        <div className="service">
          {/* section 4 */}
          <div className="software-licencing">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h5 className="service-section-title mb-4">
                    WHAT DO WE SERVE?
                  </h5>
                </div>
              </div>
              <div className="row g-4 gy-5">
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={ai}
                      className="card-img-top img-fluid"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Adobe Illustrator</h5>
                  {/* <div className="card h-100">
                                      <img src={ai} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>

                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={ps}
                      className="card-img-top img-fluid"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Adobe Photoshop</h5>
                  {/* <div className="card h-100">
                                      <img src={ps} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={cc}
                      className="card-img-top img-fluid"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Adobe Creative Cloud (CC) Suite</h5>
                  {/* <div className="card h-100">
                                      <img src={cc} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={rp}
                      className="card-img-top img-fluid"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Razorpay</h5>
                  {/* <div className="card h-100">
                                      <img src={ai} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={tally}
                      className="card-img-top img-fluid wide-img"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Tally</h5>
                  {/* <div className="card h-100">
                                      <img src={tally} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={gw}
                      className="card-img-top img-fluid wide-img"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Google Workspace</h5>
                  {/* <div className="card h-100">
                                      <img src={gw} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={m365}
                      className="card-img-top img-fluid wide-img"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Microsoft 365</h5>
                  {/* <div className="card h-100">
                                      <img src={m365} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={office}
                      className="card-img-top img-fluid wide-img"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Microsoft Office</h5>
                  {/* <div className="card h-100">
                                      <img src={office} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={ws}
                      className="card-img-top img-fluid wide-img"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Microsoft Server</h5>
                  {/* <div className="card h-100">
                                      <img src={ws} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={win}
                      className="card-img-top img-fluid wide-img"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Microsoft Windows</h5>
                  {/* <div className="card h-100">
                                      <img src={win} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={qh}
                      className="card-img-top img-fluid wide-img"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Quick Heal Total Protection</h5>
                  {/* <div className="card h-100">
                                      <img src={qh} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={qh}
                      className="card-img-top img-fluid wide-img"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Quick Head Antivirus</h5>
                  {/* <div className="card h-100">
                                      <img src={qh} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={cd}
                      className="card-img-top img-fluid"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Corel Graphics Suite</h5>
                  {/* <div className="card h-100">
                                      <img src={cd} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>
                <div className="col-6 col-md-4 col-lg-3">
                  <div className="image-container">
                    <img
                      src={cd}
                      className="card-img-top img-fluid"
                      alt="..."
                      height=" "
                      width=" "
                    />
                  </div>
                  <h5 className="card-text">Corel Graphics Draw</h5>
                  {/* <div className="card h-100">
                                      <img src={cd} className="card-img-top" alt="..." />
                                          <div className="card-body">
                                              <p className="card-text">Adobe Illustrator</p>
                                          </div>
                                  </div> */}
                </div>
              </div>

              <Swiper
                className="d-none"
                effect="fade"
                modules={[Pagination, EffectFade]}
                slidesPerView={1}
                pagination={{
                  enabled: true,
                  clickable: true,
                  dynamicBullets: true,
                  dynamicMainBullets: 1,
                }}
                // direction="horizontal"
                // breakpoints={
                //     {
                //         875: {
                //             pagination: {
                //                 enabled: true,
                //                 clickable: true,
                //                 dynamicBullets: false,
                //             },
                //             direction: "vertical",
                //             allowTouchMove: false
                //         },
                //     }
                // }
              >
                <SwiperSlide>
                  <img
                    src={problem}
                    alt=""
                    className="img-fluid"
                    height=" "
                    width=" "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={pencil}
                    alt=""
                    className="img-fluid"
                    height=" "
                    width=" "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={bulb}
                    alt=""
                    className="img-fluid"
                    height=" "
                    width=" "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={notebook}
                    alt=""
                    className="img-fluid"
                    height=" "
                    width=" "
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={img1}
                    alt=""
                    className="img-fluid"
                    height=" "
                    width=" "
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          {/* section 4 */}

          {/* section 5 */}
          <div className="section-5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="container">
                    <h5 className="service-section-title mb-4">Explore more</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row g-0">
                <div className="col-12 col-lg-4">
                  <div className="card h-100 border-start-0">
                    <div className="card-body p-3 p-lg-5 text-center">
                      <a href="/services/design-agency">
                        <h2 className="card-title">Design</h2>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card h-100">
                    <div className="card-body p-3 p-lg-5 text-center">
                      <a href="/services/business-growth">
                        <h2 className="card-title">Business Growth</h2>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card h-100 border-end-0">
                    <div className="card-body p-3 p-lg-5 text-center">
                      <a href="/services/network-and-security">
                        <h2 className="card-title">Security</h2>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section 5 */}
        </div>
        {/* footer */}
        <Footer />
        {/* footer */}
      </>
    );
  }
};

export default Software_licencing;
