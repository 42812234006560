import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import arrowRightBlue from "../assets/svg/ArrowRightBlue.svg";
import bintech_text_logo from "../assets/img/bintech-white-logo.png";
import astrik from "../assets/svg/astrik.svg";
import blob_footer from "../assets/img/blob_footer.png";
import "../css/home.css";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { FaBehance } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      {/* <Helmet>
          <title>TMT Steel Bars Manufacturer in Ahmedabad Gujarat India | Rudra TMX</title>
        </Helmet> */}

      {/* footer */}
      <div className="footer footer-services px-3 px-md-0 position-relative">
        <img
          src={blob_footer}
          alt=""
          className="img-fluid position-absolute bottom-0 end-0 d-block d-md-none footer-blob"
        />
        <div className="container">
          <div className="keep-in-touch">
            <p className="fs-5">Keep In Touch</p>
          </div>

          <div className="row gy-5 g-md-0 mb-5">
            <div className="col-12 col-md-11">
              <div className="display-5 m-0">Let's Start</div>
              <div className="display-5 mb-5">Creating Together</div>
              <NavLink
                to="/contact"
                className="animated-button d-flex align-items-center"
              >
                <div className="arrowRightBlue arrowRightBlue-left">
                  <img src={arrowRightBlue} alt="" height=" " width=" " />
                </div>
                <div className="button-text">Drop us a line</div>
                <div className="arrowRightBlue arrowRightBlue-right">
                  <img src={arrowRightBlue} alt="" height=" " width=" " />
                </div>
              </NavLink>
              {/* <a href="/contact" className="animated-button d-flex align-items-center">
                  <div className="arrowRightBlue arrowRightBlue-left">
                    <img src={arrowRightBlue} alt="" />
                  </div>
                  <div className="button-text">
                    Drop us a line
                  </div>
                  <div className="arrowRightBlue arrowRightBlue-right">
                    <img src={arrowRightBlue} alt="" />
                  </div>
                </a> */}
            </div>
            <div className="col-12 col-md-1 social-links">
              <div className="row align-items-center h-100">
                <div className="col-2 col-md-12">
                  <a
                    href="https://www.instagram.com/bintech.services/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <BsInstagram />
                  </a>
                </div>
                <div className="col-2 col-md-12">
                  <a
                    href="https://www.linkedin.com/company/bintechservices/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaLinkedinIn />
                  </a>
                </div>
                <div className="col-2 col-md-12">
                  <a
                    href="https://www.behance.net/bintech-services"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaBehance />
                  </a>
                </div>
                <div className="col-2 col-md-12">
                  <a
                    href="https://wa.me/919723816597"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaWhatsapp />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 d-flex d-md-none justify-content-between align-items-center">
              <img
                src={bintech_text_logo}
                alt=""
                className="img-fluid bintech-logo"
                height=" "
                width=" "
              />
              <img
                src={astrik}
                alt=""
                className="astrik"
                height=" "
                width=" "
              />
            </div>
          </div>
        </div>

        <div className="footnote">
          <p className="mb-5">All Rights Reserved</p>
        </div>
      </div>
      {/* footer */}
    </>
  );
};

export default Footer;
