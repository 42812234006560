import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../../assets/lotties/pfrKNMpj0i8.json";
import { Helmet } from "react-helmet";
import OurWorkSlider from "../../components/Our_work_slider";
import Footer from "../../components/Footer_services";
import design from "../../assets/svg/design.svg";
import heart from "../../assets/svg/heart.svg";
import problem from "../../assets/svg/problem.svg";
import bulb from "../../assets/svg/bulb.svg";
import pencil from "../../assets/svg/pencil.svg";
import notebook from "../../assets/svg/notebook.svg";
import pen from "../../assets/svg/services_pen.svg";
import astrik from "../../assets/svg/astrik.svg";
import Service_pen from "../../components/svgs/Service_pen";
import Service_pencil from "../../components/svgs/Service_pencil";
import Service_problem from "../../components/svgs/Service_problem";
import Service_bulb from "../../components/svgs/Service_bulb";
import Service_heart from "../../components/svgs/Service_heart";
import Service_notebook from "../../components/svgs/Service_notebook";
import Service_bulb_drop from "../../components/svgs/Service_bulb_drop";
import "../../css/home.css";
import "../../css/services.css";
import { ReactComponent as Design_ } from "../../assets/svg/design_final.svg";
import Design_svg from "../../components/svgs/Service_design";
import Loader_page from "../../components/Loader_page";

const Design = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Set loading state to true initially
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      // await new Promise((r) => setTimeout(r, 2000));

      setTimeout(() => {
        setLoading(false);
      }, 10);

      // Toggle loading state
      // setLoading((loading) => !loading);
    };

    loadData();
  }, []);

  // If page is in loading state, display
  // loading message. Modify it as per your
  // requirement.
  if (loading) {
    return <Loader_page />;
  }

  // If page is not in loading state, display page.
  else {
    return (
      <>
        <Helmet>
          <title>Bintech Services | Design Agency</title>
          <meta
            name="title"
            content="Design Agency, bringing your vision to life"
          />
          <meta
            name="description"
            content="We analyse human behavioural pattern and blend them with data science and information architecture to design a unique 'Blended experience'"
          />
          <meta
            name="keywords"
            content="design agency in Dubai, best design agency in the world, best design agency in dubai"
          />
        </Helmet>

        <div className="service design">
          {/* section 1 */}
          <div className="section-1">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 d-none d-md-flex flex-column justify-content-center">
                  <Design_svg />
                </div>
                <div className="ps-lg-5 col-12 col-md-6 d-flex flex-column justify-content-center">
                  <div className="ps-lg-5 container">
                    <h2 className="page-title">Design</h2>
                    <nav aria-label="breadcrumb" className="mb-4 mb-md-5">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#">Services</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          <a href="/services/design-agency">Design</a>
                        </li>
                      </ol>
                    </nav>
                    <h2 className="mb-3">
                      Bringing your vision to life, beautifully designed for the
                      modern world.
                    </h2>
                    <div className="d-block d-md-none my-4">
                      <Design_svg />
                    </div>
                    <p className="w-75">
                      We analyze human behavioural pattern and blend them with
                      data science and information architecture to design a
                      unique 'Blended experience'
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section 1 */}

          {/* section 2 */}
          <div className="section-2">
            <Service_pen />
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-7">
                  <div className="container">
                    <h5 className="mb-5 mb-md-4 service-section-title text-white">
                      What Do We Serve?
                    </h5>
                    <h1 className="display-6 mb-5 pe-5">
                      <img
                        src={astrik}
                        alt=""
                        className="astrik"
                        height=" "
                        width=" "
                      />
                      We help you develop your ideas into unique digital
                      designs.
                    </h1>
                  </div>
                </div>
                <div className="col-12 d-none d-md-block"></div>
              </div>

              <div className="row services">
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="container">
                    <h4>UI Design</h4>
                    <h4>UI Development</h4>
                    <h4>UI Guidelines</h4>
                    <h4>Design System</h4>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="container">
                    <h4>UX Design</h4>
                    <h4>User Research</h4>
                    <h4>UX Consultancy</h4>
                    <h4>Identity Design</h4>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="container">
                    <h4>Animations</h4>
                    <h4>Iconography</h4>
                    <h4>Illustrations</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section 2 */}

          {/* section 3 */}
          <div className="section-3">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="container">
                    <h5 className="service-section-title mb-4">
                      How WE DO IT?
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12 col-md-8">
                  <div className="container">
                    <h1 className="display-6 text-blue">
                      Going the extra mile with an extra bit effort at every
                      step.
                    </h1>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="d-block d-md-none">
                  <div className="container">
                    <div className="row">
                      <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                        <Service_pencil className="img-fluid" />
                      </div>
                      <div className="col-9 d-flex align-items-center">
                        <p className="m-0 ps-3">Understand Objective</p>
                      </div>

                      <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                        <Service_problem className="img-fluid" />
                      </div>
                      <div className="col-9 d-flex align-items-center">
                        <p className="m-0 ps-3">Right Technology</p>
                      </div>

                      <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                        <Service_bulb className="img-fluid" />
                      </div>
                      <div className="col-9 d-flex align-items-center">
                        <p className="m-0 ps-3">Development</p>
                      </div>

                      <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                        <Service_heart className="img-fluid" />
                      </div>
                      <div className="col-9 d-flex align-items-center">
                        <p className="m-0 ps-3">Careful Testing</p>
                      </div>

                      <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                        <Service_notebook className="img-fluid" />
                      </div>
                      <div className="col-9 d-flex align-items-center">
                        <p className="m-0 ps-3">Support</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row g-0 d-none d-md-flex">
                  <div className="col-12 col-sm-15 text-center">
                    <div className="card h-100">
                      <div className="card-body">
                        <Service_pencil />
                        <p className="card-text">Understand Objective</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-15 text-center">
                    <div className="card h-100">
                      <div className="card-body">
                        <Service_problem />
                        <p className="card-text">Right Technology</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-15 text-center">
                    <div className="card h-100">
                      <div className="card-body">
                        <Service_bulb />
                        <p className="card-text">Development</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-15 text-center">
                    <div className="card h-100">
                      <div className="card-body">
                        <Service_heart />
                        <p className="card-text">Careful Testing</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-15 text-center">
                    <div className="card h-100">
                      <div className="card-body">
                        <Service_notebook />
                        <p className="card-text">Support</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section 3 */}

          {/* section 4 */}
          <div className="section-4">
            <OurWorkSlider />
          </div>
          {/* section 4 */}

          {/* section 5 */}
          <div className="section-5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="container">
                    <h5 className="service-section-title mb-4">Explore more</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row g-0">
                <div className="col-12 col-lg-4">
                  <div className="card h-100 border-start-0">
                    <div className="card-body p-3 p-lg-5 text-center">
                      <a href="/services/technology">
                        <h2 className="card-title">Technology</h2>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card h-100">
                    <div className="card-body p-3 p-lg-5 text-center">
                      <a href="/services/business-growth">
                        <h2 className="card-title">Business Growth</h2>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card h-100 border-end-0">
                    <div className="card-body p-3 p-lg-5 text-center">
                      <a href="/services/network-and-security">
                        <h2 className="card-title">Security</h2>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section 5 */}
        </div>
        {/* footer */}
        <Footer />
        {/* footer */}
      </>
    );
  }
};

export default Design;
