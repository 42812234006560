import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./modules/home/Home";
import React, { useEffect, useState, useRef } from "react";
import Loader_page from "../src/components/Loader_page";
import "./App.css";

// function App() {
//   const [loading, setLoading] = useState(true);
//   useEffect(() => {
//     const loadData = async () => {
//       setTimeout(() => {
//         setLoading(false);
//       }, 10);
//     };

//     loadData();
//   }, []);
//   if (loading) {
//     return <Loader_page />;
//   } else {
//     return (
//       <div className="App">
//         <Header />
//       </div>
//     );
//   }
// }

function App() {
  return (
    <div className="App">
      <Header />
    </div>
  );
}

export default App;
