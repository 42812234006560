import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useFormik } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InquiryService from "../../_services/Inquiry-service";
import "../../css/contact.css";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { FaBehance } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import Frame from "../../assets/img/Frame.png";
import contact_us_image from "../../assets/img/contact-us.png";
import Loader_page from "../../components/Loader_page";

const Contact = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email_id: "",
      primary_contact_number: "",
      message: "",
      status: false,
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      InquiryService.saveInquiry(values)
        .then((res) => {
          if (res.data.success) {
            resetForm();
            toast.success(res.data.message, { autoClose: 1000 });
          } else {
            toast.error(res.data.message, { autoClose: 1000 });
          }
        })
        .catch((e) => console.log(e));
    },
    // validationSchema: inquiryFormSchema,
    validateOnBlur: true,
  });

  const { handleChange, handleSubmit } = formik;

  // Set loading state to true initially
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      // await new Promise((r) => setTimeout(r, 2000));

      setTimeout(() => {
        setLoading(false);
      }, 10);

      // Toggle loading state
      // setLoading((loading) => !loading);
    };

    loadData();
  }, []);

  // If page is in loading state, display
  // loading message. Modify it as per your
  // requirement.
  if (loading) {
    return <Loader_page />;
  }

  // If page is not in loading state, display page.
  else {
    return (
      <>
        <Helmet>
          <title>Bintech Services | Contact</title>
          <meta
            name="title"
            content="Contact Bintech Services Best Digital Agency"
          />
          <meta
            name="description"
            content="Please leave us your message, we will contact you as quickly as possible."
          />
          <meta
            name="keywords"
            content="Contact Bintech Services, Contact best digital agency, Contact best digital agency in Dubai"
          />
        </Helmet>
        {/* contact */}
        <div className="contact">
          <div className="contact-card">
            <ul
              className="nav nav-pills bg-white row g-0"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item col-6 px-0" role="presentation">
                <button
                  className="nav-link nav-link-1 w-100 active fs-4"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Contact Us
                </button>
              </li>
              <li className="nav-item col-6 px-0" role="presentation">
                <button
                  className="nav-link nav-link-2 w-100 fs-4"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Text Us
                </button>
              </li>
            </ul>
            <div className="tab-content py-5" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabIndex="0"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center">
                      <div className="contact-details">
                        {/* <div className="title-container mb-5">
                          <h1 className="display-4 fw-semibold text-white">
                            Phone
                          </h1>
                          <a
                            href="tel:+919723816597"
                            className="title-subtext text-white"
                          >
                            +91 9723816597
                          </a>
                        </div> */}

                        <div className="title-container mb-5">
                          <h1 className="display-4 fw-semibold text-white">
                            Email
                          </h1>
                          <a
                            href="mailto:sales@bintech.services"
                            className="title-subtext text-white"
                          >
                            sales@bintech.services
                          </a>
                        </div>

                        {/* <div className="title-container mb-5">
                          <h1 className="display-4 fw-semibold text-white">
                            Address
                          </h1>
                          <a
                            href="https://goo.gl/maps/Gi7n7DibZnjhaYEv7"
                            className="title-subtext text-white"
                          >
                            401, Samedh Complex, <br /> C G Road Ahmedabad,{" "}
                            <br /> Gujrat, 380009
                          </a>
                        </div> */}

                        <div className="d-flex justify-content-center align-items-center w-100">
                          {/* <div className="col-2"> */}
                          <a
                            href="https://www.instagram.com/bintech.services/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <BsInstagram className="text-white fs-4 me-4" />
                          </a>
                          {/* </div> */}
                          {/* <div className="col-2"> */}
                          <a
                            href="https://www.linkedin.com/company/bintechservices/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FaLinkedinIn className="text-white fs-4 me-4" />
                          </a>
                          {/* </div> */}
                          {/* <div className="col-2"> */}
                          <a
                            href="https://www.behance.net/bintech-services"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FaBehance className="text-white fs-4 me-4" />
                          </a>
                          {/* </div> */}
                          {/* <div className="col-2"> */}
                          <a
                            href="https://wa.me/919723816597"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FaWhatsapp className="text-white fs-4" />
                          </a>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 d-none d-lg-block">
                      <img
                        src={contact_us_image}
                        className="contact-us-image"
                        alt="Contact Us"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabIndex="0"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-6 d-none d-lg-block">
                      <img
                        src={Frame}
                        className="text-us-side-image"
                        alt="Message"
                      />
                    </div>
                    <div className="col-12 col-lg-6 px-4">
                      <div className="title-container">
                        <h1 className="display-4 fw-semibold text-white">
                          Message
                        </h1>
                        <div className="title-subtext text-white">
                          Please leave us your message <br /> We will answer it
                          as quickly as possible
                        </div>
                      </div>

                      <form onSubmit={handleSubmit}>
                        <div className="row gy-5 justify-content-between mb-5">
                          <div className="col-12">
                            {/* <label for="name" className="form-label h4 text-white">Name</label> */}
                            <div className="position-relative">
                              <input
                                className="effect-1"
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formik?.values.name}
                                onChange={handleChange}
                              />
                              <span className="focus-border"></span>
                            </div>
                          </div>
                          <div className="col-12">
                            {/* <label for="email" className="form-label h4 text-white">Email</label> */}
                            <div className="position-relative">
                              <input
                                className="effect-1"
                                type="email"
                                name="email_id"
                                placeholder="Email"
                                value={formik?.values.email_id}
                                onChange={handleChange}
                              />
                              <span className="focus-border"></span>
                            </div>
                          </div>
                          <div className="col-12">
                            {/* <label for="contact" className="form-label h4 text-white">Phone</label> */}
                            <div className="position-relative">
                              <input
                                className="effect-1"
                                type="tel"
                                name="primary_contact_number"
                                placeholder="Phone"
                                value={formik?.values.primary_contact_number}
                                onChange={handleChange}
                              />
                              <span className="focus-border"></span>
                            </div>
                          </div>
                          <div className="col-12">
                            {/* <label for="Message" className="form-label h4 text-white">Message</label> */}
                            <div className="position-relative">
                              <input
                                className="effect-1"
                                type="text"
                                name="message"
                                placeholder="Message"
                                value={formik?.values.message}
                                onChange={handleChange}
                              />
                              <span className="focus-border"></span>
                            </div>
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="btn text-white bg-orange"
                        >
                          Submit
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
};

export default Contact;
