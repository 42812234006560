import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import astrik from "../../assets/svg/astrik.svg";
import Footer from "../../components/Footer_services";
import "../../css/about.css";
import about_logo from "../../assets/img/about_logo.png";
import Loader_page from "../../components/Loader_page";
function About() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      setTimeout(() => {
        setLoading(false);
      }, 10);
    };
    loadData();
  }, []);
  if (loading) {
    return <Loader_page />;
  } else {
    return (
      <>
        <Helmet>
          <title>Bintech Services Digital Agency</title>
          <meta
            name='title'
            content='SYNERGY OF ART, ENGINEERING AND ENTREPRENEURSHIP'
          />
          <meta
            name='description'
            content='Bintech is a full-service digital agency that offers a range of solutions to help businesses establish a strong online presence.'
          />
          <meta
            name='keywords'
            content='best digital agency in Dubai, Digital Agency'
          />
        </Helmet>

        {/* contact */}
        <div className='about'>
          <div className='section-1'>
            <div className='container'>
              <div className='row justify-content-center'>
                <div className='col-12 col-md-8'>
                  <h1 className='about-title'>
                    <span className='text-blue'>THE SYNERGY OF</span> ART,
                    ENGINEERING AND ENTREPRENEURSHIP.
                    {/* <span className="text-blue"></span> */}
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className='section-2'>
            <div className='container'>
              <div className='row'>
                <div className='col-12 col-lg-6 d-none d-lg-flex justify-content-center align-items-center'>
                  <img
                    src={about_logo}
                    alt=''
                    className='img-fluid'
                    style={{ width: "320px", height: "113px" }}
                  />
                </div>
                <div className='col-12 col-lg-6'>
                  <div className='row mb-5'>
                    <div className='col-12'>
                      <div className='container'>
                        <span className='fw-bold display-5 text-blue position-relative'>
                          <img
                            src={astrik}
                            alt=''
                            className='astrik'
                            height=' '
                            width=' '
                          />
                          About Us
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='container'>
                        <p className='m-0 fs-6'>
                          Bintech is a full-service digital agency that offers a
                          range of solutions to help businesses establish a
                          strong online presence. With expertise in branding,
                          website development, web designing, and digital
                          marketing, Bintech provides end-to-end services to
                          clients in various industries. The company prides
                          itself on delivering high-quality work that aligns
                          with the clients' objectives and enhances their brand
                          identity. By having all of these services under one
                          roof, Bintech streamlines the process, making it easy
                          for clients to get everything they need from a single
                          provider.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='section-3'>
            <div className='row g-0 justify-content-center d-none d-xl-flex'>
              <div className='col-10'>
                <div className='row vision'>
                  <div className='col-5'>
                    <div
                      className='nav flex-column nav-pills me-3'
                      id='v-pills-tab'
                      role='tablist'
                      aria-orientation='vertical'>
                      <button
                        className='nav-link active'
                        id='v-pills-home-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-home'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-home'
                        aria-selected='true'>
                        Vision
                      </button>
                      <button
                        className='nav-link'
                        id='v-pills-profile-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-profile'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-profile'
                        aria-selected='false'>
                        Mission
                      </button>
                      <button
                        className='nav-link'
                        id='v-pills-settings-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#v-pills-settings'
                        type='button'
                        role='tab'
                        aria-controls='v-pills-settings'
                        aria-selected='false'>
                        Values
                      </button>
                    </div>
                  </div>
                  <div className='col-7'>
                    <div
                      className='tab-content'
                      id='v-pills-tabContent'>
                      <div className='w-100 text-end'>
                        <img
                          src={astrik}
                          alt=''
                          className='astrik'
                          width=' '
                          height=' '
                        />
                      </div>
                      <div
                        className='tab-pane fade show active'
                        id='v-pills-home'
                        role='tabpanel'
                        aria-labelledby='v-pills-home-tab'
                        tabndex='0'>
                        To help businesses embrace digital transformation
                        through exceptional and innovative solutions.
                      </div>
                      <div
                        className='tab-pane fade'
                        id='v-pills-profile'
                        role='tabpanel'
                        aria-labelledby='v-pills-profile-tab'
                        tabIndex='0'>
                        Our mission is to empower businesses to thrive in the
                        digital age through exceptional and innovative
                        solutions.
                      </div>
                      <div
                        className='tab-pane fade'
                        id='v-pills-settings'
                        role='tabpanel'
                        aria-labelledby='v-pills-settings-tab'
                        tabIndex='0'>
                        Passion driven individuals working as a team to derive
                        the finest quality output. <br />
                        Empathy towards customers need aids in building and
                        deriving incomparable output. <br />
                        Innovation and non conventional approach is our key to
                        generate extraordinary outcomes
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='container d-block d-xl-none'>
              <div className='row mb-5'>
                <div className='col-12'>
                  <div className='container'>
                    <span className='fw-bold display-4 position-relative text-orange'>
                      <img
                        src={astrik}
                        alt=''
                        className='astrik'
                        height=' '
                        width=' '
                      />
                      Vision
                    </span>

                    <p className='mt-4 fs-6'>
                      To help businesses embrace digital transformation through
                      exceptional and innovative solutions.
                    </p>
                  </div>
                </div>
              </div>

              <div className='row mb-5'>
                <div className='col-12'>
                  <div className='container'>
                    <span className='fw-bold display-4 position-relative text-orange'>
                      Mission
                    </span>

                    <p className='mt-4 fs-6'>
                      Our mission is to empower businesses to thrive in the
                      digital age through exceptional and innovative solutions.
                    </p>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-12'>
                  <div className='container'>
                    <span className='fw-bold display-4 position-relative text-orange'>
                      Values
                    </span>

                    <p className='mt-4 fs-6'>
                      Passion driven individuals working as a team to derive the
                      finest quality output. <br />
                      Empathy towards customers need aids in building and
                      deriving incomparable output. <br />
                      Innovation and non conventional approach is our key to
                      generate extraordinary outcomes
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default About;
