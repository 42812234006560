import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectCoverflow, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import React, { useEffect, useState } from "react";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import Footer from "../../components/Footer_services";
import OurWorkSlider from "../../components/Our_work_slider";
import technology_software from "../../assets/svg/technology_software.svg";
import heart from "../../assets/svg/heart.svg";
import problem from "../../assets/svg/problem.svg";
import bulb from "../../assets/svg/bulb.svg";
import pencil from "../../assets/svg/pencil.svg";
import notebook from "../../assets/svg/notebook.svg";
import pen from "../../assets/svg/services_pen.svg";
import bulb_drop from "../../assets/svg/bulb_drop.svg";
import astrik from "../../assets/svg/astrik.svg";
import img1 from "../../assets/img/software/img1.png";
// import img2 from "../../assets/img/software/img2.png"
// import img3 from "../../assets/img/software/img3.png"
import sliderLeft from "../../assets/svg/sliderLeft.svg";
import sliderRight from "../../assets/svg/sliderRight.svg";
import Service_pencil from "../../components/svgs/Service_pencil";
import Service_problem from "../../components/svgs/Service_problem";
import Service_bulb from "../../components/svgs/Service_bulb";
import Service_heart from "../../components/svgs/Service_heart";
import Service_notebook from "../../components/svgs/Service_notebook";
import Service_bulb_drop from "../../components/svgs/Service_bulb_drop";
import "../../css/home.css";
import "../../css/services.css";
import { ReactComponent as Software_ } from "../../assets/svg/software_animated.svg";
import "../../css/svg/software_animated.css";
import Loader_page from "../../components/Loader_page";
import { Helmet } from "react-helmet";

const Technology = () => {
  // Set loading state to true initially
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Loading function to load data or
    // fake it using setTimeout;
    const loadData = async () => {
      // Wait for two second
      // await new Promise((r) => setTimeout(r, 2000));

      setTimeout(() => {
        setLoading(false);
      }, 10);

      // Toggle loading state
      // setLoading((loading) => !loading);
    };

    loadData();
  }, []);

  // If page is in loading state, display
  // loading message. Modify it as per your
  // requirement.
  if (loading) {
    return <Loader_page />;
  }

  // If page is not in loading state, display page.
  else {
    return (
      <>
        <Helmet>
          <title>Bintech Services | Software</title>
          <meta name="title" content="We built to empower human experiences." />
          <meta
            name="description"
            content="Transforming your software or tech troubles into triumphs, one byte at a time!"
          />
          <meta
            name="keywords"
            content="Software development company in Dubai"
          />
        </Helmet>

        <div className="service technology_software">
          {/* section 1 */}
          <div className="section-1">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 d-none d-md-flex flex-column justify-content-center">
                  {/* <Support/> */}
                  <Software_ className="img-fluid" />
                </div>
                <div className="ps-lg-5 col-12 col-md-6 d-flex flex-column justify-content-center">
                  <div className="ps-lg-5 container">
                    <h2 className="page-title">Software</h2>
                    <nav aria-label="breadcrumb" className="mb-4 mb-md-5">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#">Services</a>
                        </li>
                        {/* <li className="breadcrumb-item"><a href="#">Technology</a></li> */}
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          <a href="/services/technology/software">Software</a>
                        </li>
                      </ol>
                    </nav>
                    <h2 className="mb-3">
                      Your brand's success is our binary code!
                    </h2>
                    <div className="img-fluid d-block d-md-none my-4">
                      {/* <lottie-player
                        autoplay
                        // controls
                        // loop
                        speed="0.5"
                        mode="normal"
                        src="https://lottie.host/f6fbc7d2-79eb-4560-803a-d3828f704bbc/kPFzAog38m.json"
                        // style="width: 320px"
                      ></lottie-player> */}
                      <Software_ className="img-fluid" />
                    </div>
                    {/* <img src={technology_software} alt="" className="img-fluid d-block d-md-none my-4" /> */}
                    <p className="w-75">
                      We design to empower human experiences.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section 1 */}

          {/* section 2 */}
          <div className="section-2">
            {/* <img src={bulb_drop} alt="" className="img-fluid services-pen" /> */}
            <Service_bulb_drop />
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-7">
                  <div className="container">
                    <h5 className="mb-5 mb-md-4 service-section-title text-white">
                      What Do We Serve?
                    </h5>
                    <h1 className="display-6 mb-5 pe-5">
                      <img
                        src={astrik}
                        alt=""
                        className="astrik"
                        width=" "
                        height=" "
                      />
                      Transforming your tech troubles into triumphs, one byte at
                      a time!
                    </h1>
                  </div>
                </div>
                <div className="col-12 d-none d-md-block"></div>
              </div>

              <div className="container">
                <h4 className="mb-4 service-section-title">Web App.</h4>
              </div>

              <div className="row services mb-4">
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="container">
                    <h4>Web Design</h4>
                    <h4>Web Development</h4>
                    <h4>Wordpress</h4>
                    <h4>React</h4>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="container">
                    <h4>JavaScript</h4>
                    <h4>TypeScript</h4>
                    <h4>ERP</h4>
                    <h4>HTML CSS</h4>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="container">
                    <h4>E-Commerce</h4>
                    <h4>Angular</h4>
                    <h4>UI Animations</h4>
                  </div>
                </div>
              </div>

              <div className="container">
                <h4 className="mb-4 service-section-title">Mobile App.</h4>
              </div>

              <div className="row services">
                <div className="col-12 col-sm-6 col-lg-4">
                  <div className="container">
                    <h4>IOS</h4>
                    <h4>Android</h4>
                    <h4>Hybrid</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section 2 */}

          {/* section 3 */}
          <div className="section-3">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="container">
                    <h5 className="service-section-title mb-4">
                      How WE DO IT?
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12 col-md-8">
                  <div className="container">
                    <h1 className="display-6 text-blue">
                      Going the extra mile with an extra bit effort at every
                      step.
                    </h1>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="d-block d-md-none">
                  <div className="container">
                    <div className="row">
                      <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                        <Service_pencil className="img-fluid" />
                      </div>
                      <div className="col-9 d-flex align-items-center">
                        <p className="m-0 ps-3">Understand Objective</p>
                      </div>

                      <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                        <Service_problem className="img-fluid" />
                      </div>
                      <div className="col-9 d-flex align-items-center">
                        <p className="m-0 ps-3">Chood Right Technology</p>
                      </div>

                      <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                        <Service_bulb className="img-fluid" />
                      </div>
                      <div className="col-9 d-flex align-items-center">
                        <p className="m-0 ps-3">Development</p>
                      </div>

                      <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                        <Service_heart className="img-fluid" />
                      </div>
                      <div className="col-9 d-flex align-items-center">
                        <p className="m-0 ps-3">Careful Testing</p>
                      </div>

                      <div className="col-3 d-flex align-items-center justify-content-center border-top border-bottom border-start ps-3 py-4">
                        <Service_notebook className="img-fluid" />
                      </div>
                      <div className="col-9 d-flex align-items-center">
                        <p className="m-0 ps-3">Support</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row g-0 d-none d-md-flex">
                  <div className="col-12 col-sm-15 text-center">
                    <div className="card h-100">
                      <div className="card-body">
                        <Service_pencil />
                        <p className="card-text">Understand Objective</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-15 text-center">
                    <div className="card h-100">
                      <div className="card-body">
                        <Service_problem />
                        <p className="card-text">Chood Right Technology</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-15 text-center">
                    <div className="card h-100">
                      <div className="card-body">
                        <Service_bulb />
                        <p className="card-text">Development</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-15 text-center">
                    <div className="card h-100">
                      <div className="card-body">
                        <Service_heart />
                        <p className="card-text">Careful Testing</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-15 text-center">
                    <div className="card h-100">
                      <div className="card-body">
                        <Service_notebook />
                        <p className="card-text">Support</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section 3 */}

          {/* section 4 */}
          <div className="section-4 position-relative mt-5">
            <OurWorkSlider />
          </div>
          {/* section 4 */}

          {/* section 5 */}
          <div className="section-5">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="container">
                    <h4 className="service-section-title mb-4">Explore more</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row g-0">
                <div className="col-12 col-lg-4">
                  <div className="card h-100 border-start-0">
                    <div className="card-body p-3 p-lg-5 text-center">
                      <a href="/services/design-agency">
                        <h2 className="card-title">Design</h2>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card h-100">
                    <div className="card-body p-3 p-lg-5 text-center">
                      <a href="/services/business-growth">
                        <h2 className="card-title">Business Growth</h2>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card h-100 border-end-0">
                    <div className="card-body p-3 p-lg-5 text-center">
                      <a href="/services/network-and-security">
                        <h2 className="card-title">Security</h2>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* section 5 */}

          {/* section 6 */}
          {/* <div className="section-6">
                      <div className="container">
                          <h4 className="service-section-title mb-4">
                              Other Portfolio
                          </h4>
  
                          <div className="row mb-4">
                              <div className="col-12">
                                  <div className="video-player"></div>
                              </div>
                          </div>
                          <div className="d-flex align-items-center">
                              <h2 className="fw-400 m-0 me-3">
                                  Rudra
                              </h2>
                              <p className="text-secondary fw-light fs-5 m-0">
                                  UI/UX
                              </p>
                          </div>
                      </div>
                  </div> */}
          {/* section 6 */}
        </div>
        {/* footer */}
        <Footer />
        {/* footer */}
      </>
    );
  }
};

export default Technology;
